export default class Constants {
  static ACTIVE_STATUS = 1;
  static ACTIVE_USER = "Active";
  static PENDING_USER = "Pending";
  static BOT_MALE = 1;
  static BOT_FEMALE = 2;

  static QUESTION_TYPE_NORMAL = 1;
  static QUESTION_TYPE_BONUS = 2;

  static QUESTION_STATUS_LIVE = 1;
  static QUESTION_STATUS_ON_HOLD = 2;

  static BOT_PIC_1 = "https://juegostudio-app-test.s3.amazonaws.com/uploads/profile_picture/73/1618990424-73.jpg";
  static BOT_PIC_2 = "https://juegostudio-app-test.s3.amazonaws.com/uploads/profile_picture/73/1618990391-73.jpg";
  static BOT_PIC_3 = "https://juegostudio-app-test.s3.amazonaws.com/uploads/profile_picture/73/1618990342-73.jpg";
  static BOT_PIC_4 = "https://juegostudio-app-test.s3.amazonaws.com/uploads/profile_picture/73/1618990370-73.jpg";


  static missingFieldAlertMsg = "Please fill all the required fields";
  static createQuestionSuccessMsg = "NEW QUESTION ADDED ";
  static questionUpdateSuccessMsg = "QUESTION UPDATED";
  static confirmCancelMsg = "Are you sure want to go back?"
  static saveConfirmationMsg = "Are you sure you want to save the changes?"

  static textTypeData = [{ label: "Regular", type: 1, checked: true }, { label: "Latex", type: 2, checked: false }]
  static latexOptionsData = [{
                                id: 1,
                                label: "Option 1",
                                optionType: 1,
                                option: ""
                              },
                              {
                                id: 2,
                                label: "Option 2",
                                optionType: 1,
                                option: ""
                              },
                              {
                                id: 3,
                                label: "Option 3",
                                optionType: 1,
                                option: ""
                              },
                              {
                                id: 4,
                                label: "Option 4",
                                optionType: 1,
                                option: ""
                              }]

}

