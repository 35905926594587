import React, {useState} from 'react'
import { FaPen } from 'react-icons/fa'
import { Container , Row, Col, Card, CardBody, Button} from 'reactstrap'
import CardHeader from 'reactstrap/lib/CardHeader'
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import BotEdit from './BotEdit';

function BotNames(props) {
  const [botData, setBotData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);
  const [botEditData, setBotEditData] = useState([])
  const [botEditPopup, setBotEditPopup] = useState(false);

  // api call to list bots
  const loadBotList = async (reset) => {
    if ((reset && inProgress) || !loadMoreData) return;
    setInProgress(true);

    const res = await apiClient('/admin/bot', 'GET', null, {user_id:props.userId, access_token:props.accessToken});
    if (res.responseCode === Response.STATUS_OK) {     
      setBotData(res.responseData.botList);
      
      if (res.responseData.botList.length === 0) setLoadMoreData(false);
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
    setInProgress(false);
    setLoading(false);
  }

   // opening the model and save the bot detail to state
  const handleClick = (data) => {
    setBotEditPopup(true);
    setBotEditData(data);
  };

  return (
    <Container>
      <Card>
        <CardHeader tag="h5" style={{color:"purple"}}>
          <span style={{fontSize:"30px"}}> Bot Names</span> 
          <Button className="btn btn-light  text-center" style={{float:"right"}} 
            onClick={() => handleClick(botData)} >
            <FaPen />
          </Button>
        </CardHeader>

        <CardBody>
          {loading && <h1>Loading...</h1>}
          {error && <h1 className="text-center">Error. Try Refreshing.</h1>}
          {!error && botData.length === 0 && ((!inProgress && loadBotList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}

          {botData.length !== 0 && 
            <Row>
              {botData.map(({bot_id, bot_name, bot_pic, bot_gender}, index) => (
                <Col sm="3" key={index} style={{marginTop:'20px'}}>
                <Button onClick={() => handleClick(botData)}
                  style={{color:'black'}} 
                  className="btn btn-outline-dark btn-lg  btn-light rounded-pill"
                  block>
                  {bot_name}
                </Button> 
               </Col>
               
              ))}
            </Row>
          }
        </CardBody>
      </Card>

      {/* Modal to edit bot Names  */}
      <BotEdit
        accessToken={props.accessToken}
        userId = {props.userId}
        isOpen={botEditPopup}
        botData={botEditData}
        resetAccessToken={() => { props.resetAccessToken() }}
        toggle={() => { setBotEditPopup(false); }}
        refresh = { () =>  { 
          setBotData([]);
          setLoading(true);}
        }
      />
    </Container>
  )
}

export default BotNames
