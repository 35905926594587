const formatOptionList = (optionList) => {
    return optionList.map(function (option) {
        return { value: option.option_id, label: option.option_title };
    });
  };

const formatTypeList = (typeList) => {
    return typeList.map(function (option) {
        return { value: option.type_id, label: option.type_title };
    });
};

const formatStatusList = (statusList) => {
    return statusList.map(function (option) {
        return { value: option.status_id, label: option.status_title };
    });
};

export {formatOptionList,formatTypeList,formatStatusList}  