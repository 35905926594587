import React, {useState} from 'react'
import { Input, Modal, ModalBody, ModalHeader, Form,Col, FormGroup, Button, Label } from 'reactstrap';
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import '../../App.css';
import ConfirmPopup from '../QuizQuestions/ConfirmPopup';
import ConfirmationModal from '../QuizQuestions/ConfirmationModal';

function BotEdit({botData, ...props}) {
  const [data, setData] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [backConfirmation, setBackConfirmation] = useState(false);
  const [saveConfirmation, setSaveConfirmation] = useState(false);
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [message, setMessage] = useState('')

  //confirm box to go back
  const handleCancel = () => {
      setBackConfirmation(false)
      setMessage('')
      props.toggle();
    }

  //on Change names, store it in object data
  function handleChange(evt) {
    const value = evt.target.value;
    setData({
      ...data,
      [evt.target.name]: value
    });
  }

    
  //on submitting the form, updating bot details
  const submitBotEditForm = (e) => {
    e.preventDefault();
    setSaveConfirmation(true);
    setMessage('Are you sure you want to save the changes?'); 
  }

  const saveBotNames = async () => {
      setSaveConfirmation(false); setMessage('')
      setErrorMessage('');

      //making api call to update data
      let updatedData = {user_id:props.userId, access_token:props.accessToken}
      
      let BotList = [];
      Object.keys(data).forEach(key => BotList.push({
        bot_id: key,
        bot_name: data[key]
      }));

      updatedData.bot_data = JSON.stringify(BotList);

      const res = await apiClient('/admin/bot', 'POST', updatedData);

      if (res.responseCode === Response.STATUS_OK) {
        setSuccessMsg('CHANGES SAVED')
        setSuccessConfirmation(true);
        handleRefresh();
      } else if (res.responseCode === Response.TOKEN_EXPIRED) {
        props.resetAccessToken();
      } else {
        setErrorMessage(res.responseMessage)
      }
  }

  const handleRefresh = () => {
    window.setTimeout(() => {
      setSuccessConfirmation(false);
      setSuccessMsg('');
      props.refresh()
      props.toggle(); 
    }, 2000); 
  }

  //function to reset the data field once after model is closed
  const resetData = () => {
    if(data !== '') setData('')
    if (errorMessage !== '') setErrorMessage('');
  }  

  return (
    <Modal
      isOpen={props.isOpen}
      size="lg"
      centered
    >
      <ModalHeader toggle={props.toggle}>
          Bot Name List
      </ModalHeader>

      <ModalBody style={{'overflowY':'scroll', 'position': 'relative', 'height':'50vh'}}>
        {!props.isOpen && resetData()}
        <Form onSubmit={submitBotEditForm} >
          {botData.map(({bot_id, bot_name}, index) => (
            <FormGroup row key={index}>
              <Col md={2} />
              <Label md={2}>Name {index+1}</Label>
              <Col md={6}>
                <Input 
                  type="name" value={(bot_name && data[bot_id] === undefined) ? bot_name :(data[bot_id]?data[bot_id] : '')} name={bot_id} 
                  onChange={handleChange}
                />
              </Col> 
              <Col md={2} /><br/>
            </FormGroup>
          ))}
          
          <FormGroup style={{"textAlign": "center", 'marginTop':'30px'}}>
            <Button color="danger" onClick={()=> {setMessage('Are you sure want to go back?');setBackConfirmation(true)}} > Cancel </Button> &emsp;
            <Button  color="primary"  type="submit" >Save</Button>
          </FormGroup>
          {errorMessage && <p className="mt-2 text-danger text-center">{errorMessage}</p>}
        </Form>
      </ModalBody>

      {backConfirmation && 
      <ConfirmPopup
        message={message}
        isOpen={backConfirmation}
        toggle={()=>setBackConfirmation(false)}
        handleClick={handleCancel}
      />
      }

    {saveConfirmation && 
      <ConfirmPopup
        message={message}
        isOpen={saveConfirmation}
        toggle={()=>setSaveConfirmation(false)}
        handleClick={saveBotNames}
      />
      }


      {successConfirmation && 
      <ConfirmationModal
        isOpen={successConfirmation}
        responseMessage={successMsg}
        toggle={() => { setSuccessConfirmation(false); }}
        type={"success"}
      />
      }
    </Modal>
  )
}

export default BotEdit
