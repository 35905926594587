import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import '../../App.css';
import $ from 'jquery'
  
function QuestionPagination(props) {
let [currentPage, setCurrentPage] = React.useState();
let [pagesCount, setPagesCount] = React.useState()
let [j, setJ] = React.useState(0);

  React.useEffect(() => {
    setPagesCount(props.pagesCount);
    setCurrentPage(props.currentPage)
    setJ(props.currentPage)
  }, [props])

  return (
      <Pagination size="lg" aria-label="Page navigation example" >

        <PaginationItem  disabled={currentPage <= 0}>
          <PaginationLink 
            previous
            onClick={() => props.handleClick(0)}
            href="#" />
        </PaginationItem>

        <li className={currentPage <= 0 ? 'page-item disabled':'page-item'}>
          <a href="#" className="page-link" aria-label="Previous" onClick={() => props.handleClick(currentPage-1)}>
            <span aria-hidden="true">‹</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>

        <PaginationItem >
          <PaginationLink  className="active-link"  key={1} href="#" 
            onClick={() => {
              props.handleClick(currentPage+0); 
              
              setJ(currentPage+1)}}>
              {j+1}
          </PaginationLink>
        </PaginationItem>

        <PaginationItem className={j+1 >= pagesCount ? 'page-item disabled':'page-item'}>
          <PaginationLink  key={2} href="#" 
            onClick={() => {
              props.handleClick(currentPage+1);
              $("li>a").trigger("blur");
              setJ(currentPage+2)}}>
            {j+2}
          </PaginationLink>
        </PaginationItem>

        <PaginationItem className={j+2 >= pagesCount ? 'page-item disabled':'page-item'}>
          <PaginationLink  key={3} href="#" 
            onClick={() => {
              props.handleClick(currentPage+2); 
              $("li>a").trigger("blur"); 
              setJ(currentPage+3)}}>
            {j+3}
          </PaginationLink>
        </PaginationItem>
      
        <PaginationItem className={j+3 >= pagesCount ? 'page-item disabled':'page-item'}>
          <PaginationLink  key={4} href="#" 
            onClick={() => {props.handleClick(currentPage+3); 
            $("li>a").trigger("blur");
            setJ(currentPage+4)}}>
            {j+4}
          </PaginationLink>
        </PaginationItem>
        
        <PaginationItem className={j+4 >= pagesCount ? 'page-item disabled':'page-item'}>
          <PaginationLink  key={5} href="#" 
            onClick={() => {props.handleClick(currentPage+4); 
            $("li>a").trigger("blur");
             setJ(currentPage+5)}}>
            {j+5}
          </PaginationLink>
       </PaginationItem>

        <li className={currentPage >= pagesCount-1 ? 'page-item disabled':'page-item'} >
          <a href="#" className="page-link" aria-label="Next" onClick={() => props.handleClick(currentPage + 1)}>
            <span aria-hidden="true">›</span>
            <span className="sr-only">Next</span>
          </a>
        </li>

        <PaginationItem disabled={currentPage >= pagesCount-1} >
          <PaginationLink 
            onClick={() => props.handleClick(pagesCount-1)}
            next
            href="#" />
        </PaginationItem>
            
      </Pagination>
      
  )
}

export default QuestionPagination
