import React, { useState } from 'react'
import { Container } from 'reactstrap';
import Response from "../../lib/Response";
import apiClient from "../../lib/apiClient";
import GradeTable from './GradeTable';

const containerStyle = {
  height: '90vh',
  overflowY: 'auto'
}

function GradeList(props) {
  const [gradeData, setGradeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);

  const heightCheck = event => {
    event.preventDefault();
    //loading data if screen size is big
    if (window.outerHeight > 1000 && gradeData.length < 20 && loadMoreData && !inProgress) {
      loadGradeList(false);
    }
  };

  // function to load grade list
  const loadGradeList = async (reset) => {
    if ((reset && inProgress) || !loadMoreData) return;
    setInProgress(true);

    const res = await apiClient('/admin/grade', 'GET', null, {user_id:props.userId, access_token:props.accessToken});
    
    if (res.responseCode === Response.STATUS_OK) {  
      reset ? setGradeData([...res.responseData.grade_list]) : setGradeData([...gradeData, ...res.responseData.grade_list]);
      if (!res.responseData.grade_list || res.responseData.grade_list.length < 10) setLoadMoreData(false);
      // else setPageNumber(pageNumber+1)
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
    setInProgress(false);
    setLoading(false);
  }

  React.useEffect(() => {
    props.setRouteBreadCrumbs([{route: '/grade_list', title: 'Grade List'}]);
  }, [])

  return (
    <Container className="" style={containerStyle} onLoad={heightCheck} >
      <div className="d-flex flex-column align-items-center">
        <h1>Select Grade</h1><br/>
        {loading && <h1 >Loading...</h1>}
        {error && <h1>Error. Try Refreshing.</h1>}
        {!error && gradeData.length === 0 && ((!inProgress && loadGradeList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}
      
        {gradeData.length !== 0 && 
          <GradeTable 
            data={gradeData.map(data => {
              return {
                grade:data.grade,
                subjects: data.subjects
              }
            })}
            routeBreadCrumbs={props.routeBreadCrumbs}
            setRouteBreadCrumbs={props.setRouteBreadCrumbs}
            resetAccessToken={props.resetAccessToken}
            accessToken={props.accessToken}
            userId = {props.userId}
          />
        }

        {gradeData.length !== 0 && loadMoreData && (
          <div className="loading">
            <p>Loading More....</p>
          </div>
        )}
      </div>
    </Container>
  )
}

export default GradeList
