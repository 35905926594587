import classnames from 'classnames';
import React, { useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import apiClient from "../../lib/apiClient";
import Constants from "../../lib/constant";
import Response from "../../lib/Response";
import AddLatexAnswerForm from "./addLatexForm/AddLatexAnswerForm";
import AddLatexQuestion from "./addLatexForm/AddLatexQuestionForm";
import BonusQuestionTable from "./BonusQuestionTable";
import ConfirmationModal from "./ConfirmationModal";
import ConfirmPopup from "./ConfirmPopup";
import NormalQuestionTable from "./NormalQuestionTable";
import QuestionDelete from "./QuestionDelete";
import QuestionPagination from "./QuestionPagination";
import QuizQuestionCreate from './QuizQuestionCreate';
import QuestionUpdate from './QuizQuestionUpdate';
// import '../../styles/'

const divStyle = {
  'overflowX': 'scroll', 'position': 'relative', 'height': '75vh', 'overflowY': 'auto'
}

export default function QuizQuestionList(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);
  const [questionList, setQuestionList] = useState([])
  const [pageNumber, setPageNumber] = useState(0)

  const [questionType, setQuestionType] = useState(Constants.QUESTION_TYPE_NORMAL);
  const [activeTab, setActiveTab] = useState('1');

  const [questionData, setQusetionData] = useState({});
  const [questionEditPopup, setQuestionEditPopup] = useState(false);
  const [addNewQuestionPopup, setAddNewQuestionPopup] = useState(false);
  const [optionList, setOptionList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [index, setIndex] = useState(0);
  const [limit] = useState(10);

  const [questionDeletePopup, setQuestionDeletePopup] = useState(false)
  const [questionDeleteIds, setQuestionDeleteIds] = useState([]);
  const [confirmation, setConfirmation] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  const [questionDeleteConfirmation, setQuestionDeleteConfirmation] = useState(false)

  const [addLatexQuestion, setAddLatexQuestion] = useState(false)
  const [latexQuestionType, setLatexQuestionType] = useState(1)
  const [latexQuestion, setLatexQuestion] = useState()
  const [addLatexAnswer, setAddLatexAnswer] = useState(false)
  const [confirmationPopupdata, setConfirmationPopupData] = useState({ state: false, message: "", isAlert: false })



  let history = useHistory();
  let { gradeId, subjectId } = useParams();

  const loadQuestionList = async (reset) => {
    //breadcrumb 
    if (props.routeBreadCrumbs.length === 0) return history.push('/grade_list');

    if ((reset && inProgress) || !loadMoreData) return;
    setInProgress(true);

    const params = { user_id: props.userId, access_token: props.accessToken, question_type: questionType, grade: gradeId, subject_id: subjectId, page: pageNumber + 1, limit: limit }

    const res = await apiClient('/admin/question', 'GET', null, params);

    if (res.responseCode === Response.STATUS_OK) {
      // if (reset) setQuestionDeleteIds([]);   
      setQuestionList(res.responseData.question_list);
      setIndex(res.responseData.total_count);
      console.log('page', res.responseData);
      if (res.responseData.question_list.length === 0) setLoadMoreData(false);
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
    setInProgress(false);
    setLoading(false);
  }

  const options = [
    {
      option_id: 1,
      option_title: 'Option 1'
    },
    {
      option_id: 2,
      option_title: 'Option 2'
    },
    {
      option_id: 3,
      option_title: 'Option 3'
    },
    {
      option_id: 4,
      option_title: 'Option 4'
    }
  ];

  const types = [
    {
      type_id: Constants.QUESTION_TYPE_NORMAL,
      type_title: 'Normal Question'
    },
    {
      type_id: Constants.QUESTION_TYPE_BONUS,
      type_title: 'Bonus Question'
    }
  ];

  const statuses = [
    {
      status_id: Constants.QUESTION_STATUS_LIVE,
      status_title: 'Live'
    },
    {
      status_id: Constants.QUESTION_STATUS_ON_HOLD,
      status_title: 'On Hold'
    }
  ];

  React.useEffect(() => {
    setOptionList(options)
    setTypeList(types)
    setStatusList(statuses)
  }, [])

  React.useEffect(() => {
    setLoadMoreData(true)
    loadQuestionList(true)
  }, [pageNumber])


  //function to load the quiz list
  const toggleTab = (tab, type) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setQuestionType(type);
      setQuestionList([]);
      setQuestionDeleteIds([]);
      setQuestionDeletePopup(false)
      setPageNumber(0);
      setLoadMoreData(true)
    }
  }

  const handleClick = (data) => { 
    setQusetionData(data);
    if(data.question_text_type === 1){ 
      setQuestionEditPopup(true) 
    }
    else{  
      setLatexQuestionType(data.question_text_type)
      setLatexQuestion(data.question_text)
      setAddLatexQuestion(true)
    }
  };

  const heightCheck = event => {
    event.preventDefault();
    if (window.outerHeight > 1000 && questionList.length < 10 && loadMoreData && !inProgress) {
      loadQuestionList(false);
    }
  };

  const deleteQuestionData = () => {
    if (questionDeletePopup) {
      if (questionDeleteIds.length === 0) {
        alert("Please select question to delete")
      } else {
        setQuestionDeleteConfirmation(true)
      }
    }
    setQuestionDeletePopup(true);
  }

  //function to delete the selected question
  const deleteQuestions = async () => {
    let questionIds = JSON.stringify(questionDeleteIds);

    const res = await apiClient('/admin/deleteQuestion', 'POST', { user_id: props.userId, access_token: props.accessToken, question_master_ids: questionIds });

    if (res.responseCode === Response.STATUS_OK) {
      //reloading the quiz list
      confirmationModal('SELECTED QUESTION HAVE BEEN DELETED SUCCESSFULLY');
      setQuestionList([]);
      setQuestionDeletePopup(false);
      setLoadMoreData(true)
      loadQuestionList(true);
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      confirmationModal(res.responseMessage);
    }
  }

  const confirmationModal = (message) => {
    setResponseMessage(message);
    setConfirmation(true);
    window.setTimeout(() => {
      setConfirmation(false);
      setResponseMessage('');
    }, 2000);
  }


  const handleQuestionTypeChange = (value) => {
    setLatexQuestionType(value)
  }
  const handleQuestionDescription = (value) => {
    setLatexQuestion(`${value}`)
  }
  const handleProceedToAdd = () => {
    if (latexQuestion) {
      setAddLatexQuestion(false)
      setAddLatexAnswer(true)
    }
    else {
      setConfirmationPopupData({ state: true, message: Constants.missingFieldAlertMsg, isAlert: true })
    }
  }

  const handleResetDefaultData = () => {
    setLatexQuestionType(1)
    setLatexQuestion()
    setQusetionData({})
  }



  return (
    <div>
      <Row>
        <Col md={1}></Col>
        <Col xl={10} lg={12} md={12}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggleTab('1', Constants.QUESTION_TYPE_NORMAL); }}
              >
                <h4 style={{ color: "black", 'fontWeight': '400' }}>Normal Question</h4>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggleTab('2', Constants.QUESTION_TYPE_BONUS); }}
              >
                <h4 style={{ color: "black", 'fontWeight': '400' }}>Bonus Question</h4>
              </NavLink>
            </NavItem>
            <NavItem className="ml_auto">
              <Button color="primary" size="md" onClick={() => setAddNewQuestionPopup(true)} >ADD NEW QUESTION</Button>
            </NavItem>
            <NavItem className="ml_1">
              <Button color="primary" size="md" onClick={() => setAddLatexQuestion(true)}>ADD LATEX QUESTION</Button>
            </NavItem>
          </Nav>


          <TabContent activeTab={activeTab}>
            <br />
            <TabPane tabId="1">
              <Container fluid={true} onLoad={heightCheck}>
                <div className="d-flex flex-column align-items-center" style={divStyle} >
                  {loading && <h1>Loading...</h1>}
                  {error && <h1>Error. Try Refreshing.</h1>}
                  {!error && questionList.length === 0 && ((!inProgress && loadQuestionList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}

                  {questionList.length !== 0 && (
                    <NormalQuestionTable
                      headers={
                        [
                          'QUESTION ID',
                          'QUESTION TEXT',
                          'ATTEMPT RATIO',
                          'QUESTION STATUS'
                        ]}
                      data={questionList}
                      handleClick={(normal) => { handleClick(normal) }}
                      routeBreadCrumbs={props.routeBreadCrumbs}
                      setRouteBreadCrumbs={props.setRouteBreadCrumbs}
                      resetAccessToken={props.resetAccessToken}
                      accessToken={props.accessToken}
                      userId={props.userId}
                      isDelete={questionDeletePopup}
                      questionDeleteIds={questionDeleteIds}
                      setQuestionDeleteIds={(updatedIds) => { setQuestionDeleteIds(updatedIds) }}
                    />
                  )}
                </div>

                <Row>
                  <Col md={6} >
                    <div style={{ margin: '30px', justifyContent: 'center' }}>
                      <Button onClick={() => deleteQuestionData()} color="primary" size="lg" > Delete </Button> &emsp;
                      {questionDeletePopup &&
                        <Button onClick={() => setQuestionDeletePopup(false)} color="danger" size="lg" > Cancel </Button>
                      }
                    </div>
                  </Col>
                  <Col md={6}>
                    <QuestionPagination
                      currentPage={pageNumber}
                      pagesCount={(Math.ceil(index / limit))}
                      handleClick={(i) => { setPageNumber(i); }}
                    />
                  </Col>
                </Row>
              </Container>

            </TabPane>
            <TabPane tabId="2">
              <Container fluid={true} onLoad={heightCheck} >
                <div className="d-flex flex-column align-items-center" style={divStyle}>
                  {loading && <h1 className="text-center">Loading...</h1>}
                  {error && <h1 className="text-center">Error. Try Refreshing.</h1>}
                  {!error && questionList.length === 0 && ((!inProgress && loadQuestionList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}

                  {questionList.length !== 0 && (
                    <BonusQuestionTable
                      headers={
                        [
                          'QUESTION ID',
                          'QUESTION TEXT',
                          'ATTEMPT RATIO',
                          'QUESTION STATUS'
                        ]}
                      data={questionList}
                      handleClick={(bonus) => { handleClick(bonus) }}
                      routeBreadCrumbs={props.routeBreadCrumbs}
                      setRouteBreadCrumbs={props.setRouteBreadCrumbs}
                      resetAccessToken={props.resetAccessToken}
                      accessToken={props.accessToken}
                      userId={props.userId}
                      isDelete={questionDeletePopup}
                      questionDeleteIds={questionDeleteIds}
                      setQuestionDeleteIds={(updatedIds) => { setQuestionDeleteIds(updatedIds) }}
                    />
                  )}
                </div>

                <Row>
                  <Col md={6} >
                    <div style={{ margin: '30px', justifyContent: 'center' }}>
                      <Button onClick={() => deleteQuestionData()} color="primary" size="lg" > Delete </Button> &emsp;
                      {questionDeletePopup &&
                        <Button onClick={() => setQuestionDeletePopup(false)} color="danger" size="lg" > Cancel </Button>
                      }
                    </div>
                  </Col>
                  <Col md={6}>
                    <QuestionPagination
                      currentPage={pageNumber}
                      pagesCount={(Math.ceil(index / limit))}
                      handleClick={(i) => { setPageNumber(i); }}
                    />
                  </Col>
                </Row>

              </Container>
            </TabPane>
          </TabContent>
        </Col>
      </Row>

      {/* Model to update existing reward */}
      <QuestionUpdate
        accessToken={props.accessToken}
        userId={props.userId}
        isOpen={questionEditPopup}
        questionData={questionData}
        optionList={optionList}
        typeList={typeList}
        statusList={statusList}
        resetAccessToken={() => { props.resetAccessToken() }}
        toggle={() => { setQuestionEditPopup(false); }}
        refresh={(type) => {
          setLoadMoreData(true);
          setPageNumber(pageNumber);
          setQuestionType(type);
          setQuestionList([]);
          loadQuestionList(false)
        }}
      />

      {/* Model to create new quiz */}
      <QuizQuestionCreate
        accessToken={props.accessToken}
        userId={props.userId}
        isOpen={addNewQuestionPopup}
        optionList={optionList}
        typeList={typeList}
        statusList={statusList}

        refresh={() => {
          setLoadMoreData(true);
          setPageNumber(pageNumber);
          setQuestionType(questionType);
          setQuestionList([]);
          loadQuestionList(true)
        }}

        resetAccessToken={() => { props.resetAccessToken() }}
        toggle={() => { setAddNewQuestionPopup(false); }}
        gradeId={gradeId}
        subjectId={subjectId}
      />

      {addLatexQuestion && <AddLatexQuestion
        isOpen={addLatexQuestion}
        toggle={() => { setAddLatexQuestion(false) }}
        latexQuestion={latexQuestion}
        questionType={latexQuestionType}
        setAddLatexQuestion={setAddLatexQuestion}
        handleQuestionTypeChange={handleQuestionTypeChange}
        handleQuestionDescription={handleQuestionDescription}
        handleProceedToAdd={handleProceedToAdd}
      />}
      
      {addLatexAnswer && <AddLatexAnswerForm
        data={{
          user_id: props.userId,
          access_token: props.accessToken,
          grade: gradeId, 
          subject_id: subjectId,
          question_text_type: latexQuestionType,
          question_text: latexQuestion
        }}
        isOpen={addLatexAnswer}
        toggle={() => { setAddLatexAnswer(false) }}
        closeLatexQuestionForm={props.toggle}
        optionList={optionList}
        typeList={typeList}
        statusList={statusList}
        resetAccessToken={() => { props.resetAccessToken() }}
        refresh={() => {
          setLoadMoreData(true);
          setPageNumber(pageNumber);
          setQuestionType(questionType);
          setQuestionList([]);
          loadQuestionList(true)
        }}
        defaultData = {questionData}
        handleResetDefaultData={handleResetDefaultData}

      />}

      {/* Model to show warning about deleting question */}
      <QuestionDelete
        accessToken={props.accessToken}
        isOpen={questionDeleteConfirmation}
        delete={() => { deleteQuestions(); }}
        toggle={() => { setQuestionDeleteConfirmation(false); }}
      />
      <ConfirmationModal
        isOpen={confirmation}
        responseMessage={responseMessage}
        toggle={() => { setConfirmation(false); }}
      />
      {confirmationPopupdata.state &&
        <ConfirmPopup
          message={confirmationPopupdata.message}
          isOpen={confirmationPopupdata.state}
          toggle={() => setConfirmationPopupData({ state: false, message: "", isAlert: false })}
          isAlert={confirmationPopupdata.isAlert}
        />
      }

    </div>
  );
}
