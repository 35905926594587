import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import MainLayout from './MainLayout';
import PageSpinner from '../../components/PageSpinner';
import GradeList from '../Grade/GradeList';
import QuizQuestionList from '../QuizQuestions/QuizQuestionList';
import BotList from '../Bots/BotList';
import BannersList from '../Banners/BannersList';
import { BASE_PATH } from '../../config/settings';
import BannerLanding from '../Banners/BannerLanding';

//different routes of the project
const routePaths = [
  { to: '/', title: [{route: '/', title: 'Grade List'}], Component: GradeList },
  { to: '/grade_list', title: [{route: '/grade_list', title: 'Grade List'}], Component: GradeList },
  { to: '/question_list/:gradeId/:subjectId', title: '', Component: QuizQuestionList },
  { to: '/bot_list', title: [{route: '/bot_list', title: 'Bot List'}], Component: BotList },
  { to: '/banners_list', title: [{route: '/banners_list', title: 'Banners List'}], Component: BannerLanding }
];

export default function Home(props) {
  // const [routeTitle, setRouteTitle] = useState("");
  const [routeBreadCrumbs, setRouteBreadCrumbs] = useState([]);
  return (
    <Router basename={BASE_PATH}>
      <div className="d-flex">
        <MainLayout {...props} breakpoint={props.breakpoint} routeTitle={routeBreadCrumbs}>
          <React.Suspense fallback={<PageSpinner />}>
            {routePaths.map(({ to, Component, title }) => (<Route key={to} exact path={to} render={() => { 
              // setRouteTitle(title ? title : routeBreadCrumbs); 
              return (<Component {...props} setRouteBreadCrumbs={(updatedRoutes) => {setRouteBreadCrumbs(updatedRoutes)}} routeBreadCrumbs={routeBreadCrumbs}/>) }} />))}
          </React.Suspense>
        </MainLayout>
      </div>
    </Router>
  );
}
