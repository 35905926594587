import React from 'react';
import { Modal, ModalBody , Button} from 'reactstrap';

function Logout(props) {
  

  return (
    <Modal isOpen={props.isOpen}
    size="md"
    centered>
      <ModalBody>
        <div className='text-center'>
          <p className='m-4'><b>Are you sure you want to logout?</b></p>
          <Button  color="danger" onClick={()=>props.toggle()}>No</Button>&emsp;&emsp;
          <Button  color="secondary" onClick={()=>{props.resetAccessToken();}} >Yes</Button>

        </div>
      </ModalBody>
    </Modal>
  );
}
export default Logout
