import React from 'react';
import { Modal, ModalBody, Form, Col, FormGroup, Label } from 'reactstrap';

function ConfirmationModal(props) {
  let styles= {
    'fontWeight': "bold",
    'fontSize': '26px', 
    'paddingTop': 'calc(0.375rem + 1px)', 
    'paddingBottom': 'calc(0.375rem + 1px)',
    'color': 'green'
  }

  let stylesWarning= {
    'fontWeight': "bold",
    'fontSize': '26px', 
    'paddingTop': 'calc(0.375rem + 1px)', 
    'paddingBottom': 'calc(0.375rem + 1px)',
    'color': 'red'
  }

  return (
    <Modal
      isOpen={props.isOpen}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <Form>
          <FormGroup style={{"textAlign": "center"}}>
            <Col sm={90}>
              <Label style={props.type === "success" ? styles : stylesWarning}>{props.responseMessage}</Label>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
}

export default ConfirmationModal
