import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import ConfirmationModal from './ConfirmationModal';
import ConfirmPopup from './ConfirmPopup';

export default function CreateModal(props) {
  let [isDisabled, setIsDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [question, setQuestion] = useState('');
  const [optionOne, setOptionOne] = useState('');
  const [optionTwo, setOptionTwo] = useState('');
  const [optionThree, setOptionThree] = useState('');
  const [optionFour, setOptionFour] = useState('');
  const [defaultAnswer, setDefaultAnswer] = useState([]);
  const [defaultType, setDefaultType] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState([]);
  const [answerDropdown, setAnswerDropdown] = useState([]);
  const [typeDropdown, setTypeDropdown] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);
  const [time, setTime] = useState(0);

  const [backConfirmation, setBackConfirmation] = useState(false);
  const [createConfirmation, setCreateConfirmation] = useState(false);
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [message, setMessage] = useState('')

  //function to load dropdowns(options, type, status)
  const loadDropdowns = () => {
    let selectableOptions = [];
    let selectableType = [];
    let selectableStatus = [];
      
    //initializing the answer  dropdown
    props.optionList.map(option => {
      selectableOptions.push({value: option.option_id, label: option.option_title});
      return option;
    });
  
    if (answerDropdown.length === 0) setAnswerDropdown(selectableOptions);
  
    //initializing the type dropdown
    props.typeList.map(type => {
      selectableType.push({value: type.type_id, label: type.type_title});
      return type;
    });

    if (typeDropdown.length === 0) setTypeDropdown(selectableType);

    //initializing the status dropdown
    props.statusList.map(status => {
      selectableStatus.push({value: status.status_id, label: status.status_title});
        return status;
    });

    if (statusDropdown.length === 0) setStatusDropdown(selectableStatus);

  }

  const submitCreateForm =  (e) => {
    e.preventDefault();
    console.log(questionId,'-',question,'-',optionOne,'-',optionTwo,'-',optionThree,'-',optionFour,'-',defaultAnswer.value,'-',defaultType.value.time);
    if ((question.trim() === "") ||
        (optionOne.trim() === "") || (optionTwo.trim() === "") ||
        (optionThree.trim() === "") || (optionFour.trim() === "") || 
        (typeof defaultAnswer.value  === "undefined") || (typeof defaultType.value === "undefined") || (typeof defaultStatus.value === "undefined") 
      ) 
    {
      setErrorMessage('Please fill all the required fields');
    } else {
      setCreateConfirmation(true);
      setMessage('Are you sure you want to save the changes?');
    }
    
  }

 // api call to create question
  const handleSaveChanges = async () => {
    setCreateConfirmation(false); setMessage('')
    let updateData = {user_id:props.userId, access_token:props.accessToken}
    if (typeof questionId !== "undefined" && questionId.trim() !== "") updateData.question_id = questionId;
    if (typeof question !== "undefined" && question.trim() !== "") updateData.question_text = question;
    if (typeof optionOne !== "undefined" && optionOne.trim() !== "") updateData.option1 = optionOne;
    if (typeof optionTwo !== "undefined" && optionTwo.trim() !== "") updateData.option2 = optionTwo;
    if (typeof optionThree !== "undefined" && optionThree.trim() !== "") updateData.option3 = optionThree;
    if (typeof optionFour !== "undefined" && optionFour.trim() !== "") updateData.option4 = optionFour;
    if (typeof time !== "undefined" && time.trim() !== "") updateData.time = time;
    if (typeof defaultAnswer.value !== "undefined" ) updateData.answer = defaultAnswer.value;
    if (typeof defaultType.value !== "undefined" ) updateData.type = defaultType.value;
    if (typeof defaultStatus.value !== "undefined" ) updateData.question_status = defaultStatus.value;
    updateData.grade = props.gradeId;
    updateData.subject_id = props.subjectId;
    console.log(updateData)
    const res = await apiClient('/admin/question', 'POST', updateData, null);
    if (res.responseCode === Response.STATUS_OK) {
      //reloading the table and closing the model
      setSuccessMsg('NEW QUESTION ADDED')
      setSuccessConfirmation(true);
      handleRefresh();
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setErrorMessage(res.responseMessage)
    }
  }

  const handleRefresh = () => {
    window.setTimeout(() => {
      setSuccessConfirmation(false);
      setSuccessMsg('');
      props.refresh()
      props.toggle(); 
    }, 2000); 
  }

  //function to reset the data field once after model is closed
  const resetData = () => {
    if (questionId !== '') setQuestionId('');
    if (question !== '') setQuestion('');
    if (optionOne !== '') setOptionOne('');
    if (optionTwo !== '') setOptionTwo('');
    if (optionThree !== '') setOptionThree('');
    if (optionFour !== '') setOptionFour('');
    if(defaultAnswer.length !== 0) setDefaultAnswer([])
    if(defaultType.length !== 0) setDefaultType([])
    if(defaultStatus.length !== 0) setDefaultStatus([])
    if (errorMessage !== '') setErrorMessage('');
    if (loading !== true) setLoading(true);
    if (isDisabled === true) setIsDisabled(false);
  }

  const handleCancel = () => {
    setBackConfirmation(false)
    setMessage('')
    props.toggle();
  }

  return (
    <Modal
      isOpen={props.isOpen}
     
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={props.toggle}>
          Add New Question
      </ModalHeader>
      <ModalBody>
        {props.isOpen && loadDropdowns()}
        {!props.isOpen && resetData()}
     
          <Form onSubmit={submitCreateForm} >
             {/* <FormGroup row>
              <Label for="question_id" sm={4}>ID No.</Label>
              <Col sm={5}>
                <Input 
                  type="text" value={questionId} name="question_id" 
                  onChange={(e) => { setQuestionId(e.target.value);}}
                  />
              </Col>
            </FormGroup>
            <br/> */}

            <FormGroup row>
              <Label for="question_title" sm={4}>Question Text</Label>
              <Col sm={5}>
                <Input 
                  type="textarea" value={question} name="question_title" 
                  onChange={(e) => { setQuestion(e.target.value);}}
              
                  />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_one" sm={4}>Option One</Label>
              <Col sm={5}>
                <Input 
                  value={optionOne}
                  onChange={(e) => { setOptionOne(e.target.value);}} type="text" name="option_one" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_two" sm={4}>Option Two</Label>
              <Col sm={5}>
                <Input 
                   value={optionTwo}
                  onChange={(e) => { setOptionTwo(e.target.value);}} type="text" name="option_two" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_three" sm={4}>Option Three</Label>
              <Col sm={5}>
                <Input 
                  value={optionThree}
                  onChange={(e) => { setOptionThree(e.target.value);}} type="text" name="option_three" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_four" sm={4}>Option Four</Label>
              <Col sm={5}>
                <Input value={optionFour}
                  onChange={(e) => { setOptionFour(e.target.value);}} type="text" name="option_four" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
            <Label for="correct_answer" sm={4}>Answer</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultAnswer}
                name="correct_answer"
                options={answerDropdown}
                onChange={(e) => setDefaultAnswer(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>
          <br/>

          <FormGroup row>
            <Label for="question_type" sm={4}>Type</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultType}
                name="question_type"
                options={typeDropdown}
                onChange={(e) => setDefaultType(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>

          <br/>
          <FormGroup row>
            <Label for="status" sm={4}>Status</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultStatus}
                name="status"
                options={statusDropdown}
                onChange={(e) => setDefaultStatus(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>
          <br/>
          <FormGroup row>
              <Label for="time" sm={4}>Time(Seconds)</Label>
              <Col sm={5}>
                <Input value={time}
                  onChange={(e) => { setTime(e.target.value);}} type="number" name="time"  />
              </Col>
            </FormGroup>
            <br/>
          <br/>
            <FormGroup style={{"textAlign": "center"}}>
            <Button color="danger" onClick={()=> {setMessage('Are you sure want to go back?');setBackConfirmation(true)}} > Cancel </Button> &emsp;
            <Button  color="primary" disabled={isDisabled}  type="submit" >Save</Button>

            </FormGroup>
            {errorMessage && <p className="mt-2 text-danger text-center">{errorMessage}</p>}

          </Form>
      
          {backConfirmation && 
            <ConfirmPopup
              message={message}
              isOpen={backConfirmation}
              toggle={()=>setBackConfirmation(false)}
              handleClick={handleCancel}
            />
          }

          {createConfirmation && 
          <ConfirmPopup
            message={message}
            isOpen={createConfirmation}
            toggle={()=>setCreateConfirmation(false)}
            handleClick={handleSaveChanges}
          />
          }

          {successConfirmation && 
          <ConfirmationModal
            isOpen={successConfirmation}
            responseMessage={successMsg}
            toggle={() => { setSuccessConfirmation(false); }}
            type={"success"}
          />
          }

      </ModalBody>
    </Modal>
  );
}