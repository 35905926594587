import React from 'react'
import { Container, Button, CardBody, Card, Row, Col, CardHeader } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import '../../App.css'

function GradeTable({ data, ...restProps }) {
  let history = useHistory();
  
  const displayQuestionList = (grade, subject_name, subject_id) => {
    let path = `/question_list/${grade}/${subject_id}`;
    restProps.setRouteBreadCrumbs([{route: '/grade_list', title: 'Grade List'}, {route: path, 'title': subject_name}]);
    history.push(path);
  }

  return (
    <Container>
      {data.map(({ grade, subjects}, index) => (
        <div  key={index}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader key={index} style={{color:"purple"}}>Grade {grade}</CardHeader>
                <CardBody>
                  {subjects.map(({subject_id, subject_name}, i) => (
                    <span key={i}>
                      <Button className='grade-btn'
                        style={{background:"white", color:"black", borderColor:"grey"}} 
                        key={subject_id} onClick={() => displayQuestionList(grade,subject_name,subject_id)}>
                        {subject_name}
                      </Button> &emsp; &emsp;
                    </span>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row><br/>
        </div>
      ))}
   </Container>
  )
}

export default GradeTable
