import React from 'react';
import { MdStars, MdExitToApp, MdGamepad, MdImage } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink as BSNavLink, } from 'reactstrap';
import bn from 'utils/bemnames';
import Logout from './Logout';

const sidebarBackground = {
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

//side bar content can be defined here
const navItems = [
  { to: '/grade_list', name: 'Quiz Control', exact: false, Icon: MdStars },
  { to: '/bot_list', name: 'Bots', exact: false, Icon: MdGamepad },
  { to: '/banners_list', name: 'Banners', exact: false, Icon: MdImage }
];

const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
    isOpenLogoutConfirmation:false
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    // console.log(this.props)

    return (
      <aside className={bem.b()}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <BSNavLink className="navbar-brand d-flex"
              tag={NavLink}
              to={'/'}
              exact={false}>
                
              <span style={{'fontWeight': 'bold', textAlign: "center", color:"white", whiteSpace: "break-spaces"}}>
              {/* <img alt={1} src={"/title.png"} height='100' width='100' /> */}
              <h3>ULearning Quizzo</h3>
              </span>
            </BSNavLink>
          </Navbar>
          <Nav vertical>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  // activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e('nav-item-icon')} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
          <Nav vertical>
            <NavItem key={4} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-logout-${4}`}
                className="text-uppercase btn"
                onClick={()=> {this.setState({isOpenLogoutConfirmation:true})}}
                // activeClassName="active"
                style={{ cursor: 'pointer' }}
              >
                <MdExitToApp className={bem.e('nav-item-icon')} />
                <span className="">Logout</span>
              </BSNavLink>
            </NavItem>
          </Nav>
        </div>
        <Logout
          isOpen={this.state.isOpenLogoutConfirmation}
          toggle={()=>this.setState({isOpenLogoutConfirmation:false})}
          resetAccessToken={this.props.resetAccessToken}
        />
      </aside>
    );
  }
}

export default Sidebar;
