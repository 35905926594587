import React from 'react';
import { Modal, ModalBody, ModalHeader, Form, Button } from 'reactstrap';
import Constants from '../../../lib/constant';
import styles from "./addLatexForm.scss"
import { MathJax, MathJaxContext } from 'better-react-mathjax'



export default function AddLatexQuestion(props) {

    return (
        <Modal
            isOpen={props.isOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader toggle={props.toggle} >Add Latex Question</ModalHeader>
            <ModalBody>
                <Form>
                    <div className="question">
                        <div className="question__type">
                            {Constants.textTypeData.map((item, index) => (
                                <div key={index} className="question__type__selection">
                                    <label className="question__type__selection__label">{item.label}</label>
                                    <input className="question__type__selection__btn" type="radio" name="question_type" checked={props.questionType === item.type ? true : false} value={item.type} onChange={(e) => { props.handleQuestionTypeChange(+e.target.value) }} />
                                </div>
                            ))}
                        </div>
                        <div className="question__description">
                            <label className="question__description__label">Enter Question Description</label>
                            <textarea className="question__description__input" rows="5" value={props.latexQuestion} onChange={(e) => {
                                props.handleQuestionDescription(e.target.value)
                            }
                            } />
                        </div>
                        {(props.questionType && props.questionType === 2) &&
                            <div className="question__preview">
                                <label className="question__preview__label">Preview</label>
                                <div className="question__preview__content">
                                    {props.latexQuestion &&
                                        <MathJaxContext version={3}>
                                            <MathJax 
                                                renderMode={"pre"}
                                                typesettingOptions={{ fn: "tex2chtml" }}
                                                dynamic  
                                                text= {props.latexQuestion ? props.latexQuestion : ""}>
                                            </MathJax>
                                        </MathJaxContext>
                                    }
                                </div>
                            </div>
                        }
                        <div className="question__add__ans__btn">
                            <Button color="primary" onClick={props.handleProceedToAdd} >Proceed to Add Answers</Button>
                        </div>
                    </div>
                </Form>
            </ModalBody>
        </Modal>

    );
}