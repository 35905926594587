import React from 'react';
import { Modal, ModalBody, ModalHeader, Form, Col, FormGroup, Button, Label } from 'reactstrap';

function QuestionDelete(props) {
 
  //function to delete questions
  const handleClick = () => {
    props.delete()
    props.toggle()
  }

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalHeader toggle={props.toggle}>
          Delete Questions
      </ModalHeader>
      <ModalBody>
          <Form>
            <FormGroup style={{"textAlign": "center"}}>
            <Col sm={90}>
            <Label style={{'paddingTop': 'calc(0.375rem + 1px)', 'paddingBottom': 'calc(0.375rem + 1px)'}}>Are You sure want to delete question?</Label>
              </Col>
            </FormGroup>
            <FormGroup style={{"textAlign": "center"}}>
            <Col sm={90}>
            <Button color="danger" onClick={(e) => { handleClick() }}>Delete</Button> &emsp;
            <Button color="primary" onClick={props.toggle}>Cancel</Button>
              </Col>
            </FormGroup>
          </Form>
      </ModalBody>
    </Modal>
  );
}

export default QuestionDelete
