import React, { useEffect, useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader, Form, Button, Label } from 'reactstrap';
import Select from 'react-select';
import Constants from '../../../lib/constant';
import styles from "./addLatexForm.scss";
import { MathJax, MathJaxContext } from 'better-react-mathjax'
import apiClient from '../../../lib/apiClient';
import ConfirmationModal from '../ConfirmationModal';
import ConfirmPopup from '../ConfirmPopup';
import Response from '../../../lib/Response';
import { formatOptionList, formatStatusList, formatTypeList } from '../../../helper/formatFilterList';



export default function AddLatexAnswerForm({ defaultData,handleResetDefaultData, ...props }) {


    const [optionsData, setOptionsData] = useState(Constants.latexOptionsData)
    const [answer, setAnswer] = useState()
    const [type, setType] = useState()
    const [status, setStatus] = useState()
    const [formData, setFormData] = useState([])
    const [successConfirmation, setSuccessConfirmation] = useState({ state: false, message: "" })
    const [confirmationPopupdata, setConfirmationPopupData] = useState({ state: false, message: "", isAlert: false, type: 1 })


    useEffect(() => {
        console.log(Object.keys(defaultData).length !== 0 && defaultData);
        if((Object.keys(defaultData).length !== 0)){
            const defaultOptionData = defaultData.options.map((item, i) => {
                return {
                    id: i+1,
                    label: `Option ${i+1}`,
                    optionType: item.option_text_type,
                    option: item.option_text
                }
            })
            setOptionsData(defaultOptionData)
            const optionIndex = defaultData.options.findIndex(item => item.option_id === defaultData.answer_id)
            setAnswer((formatOptionList(props.optionList)).find(item => item.value === optionIndex+1))
            setType((formatTypeList(props.typeList)).find(item => item.value === defaultData.type))
            setStatus((formatStatusList(props.statusList)).find(item => item.value === defaultData.question_status))
        }
    }, [])

    

    const handleOptionTextType = (e) => {
        const newData = optionsData.map(item =>
            (item.id === +e.target.id) ? { ...item, optionType: +e.target.value } : item)
        setOptionsData(newData)
    }

    const handleOptionDescriptionChange = (e) => {
        const newData = optionsData.map(item =>
            (item.id === +e.target.id) ? { ...item, option: e.target.value } : item)
        setOptionsData(newData)
    }


    const handleRefresh = () => {
        window.setTimeout(() => {
            setSuccessConfirmation({ state: false, message: "" })
            props.refresh()
            props.toggle();
        }, 2000);
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const data = {
            ...props.data,
            option1: (optionsData[0].option) ? optionsData[0].option : "",
            option1_type: (optionsData[0].optionType) ? (optionsData[0].optionType) : "",
            option2: (optionsData[1].option) ? (optionsData[1].option) : "",
            option2_type: (optionsData[1].optionType) ? optionsData[1].optionType : "",
            option3: (optionsData[2].option) ? optionsData[2].option : "",
            option3_type: (optionsData[2].optionType) ? optionsData[2].optionType : "",
            option4: (optionsData[3].option) ? optionsData[3].option : "",
            option4_type: (optionsData[3].optionType) ? optionsData[3].optionType : "",
            answer: (answer) ? answer.value : "",
            type: (type) ? type.value : "",
            question_status: (status) ? status.value : "",
        }
        for (let [key, value] of Object.entries(data)) {  //checking if all fields are filled
            if (typeof value === 'undefined' || value === '') {
                setConfirmationPopupData({ state: true, message: Constants.missingFieldAlertMsg, isAlert: true, type: 1 });
                return;
            }
        }
        setFormData(data)
        setConfirmationPopupData({ state: true, message: Constants.saveConfirmationMsg, isAlert: false, type: 2 });
    }

    // api call to create question
    const handleSave = async () => {
        setConfirmationPopupData({ state: false, message: "", isAlert: false })
        try {
            let reqData = { ...formData }
            if(defaultData.question_master_id) reqData.question_master_id = defaultData.question_master_id
           
            const res =  (defaultData.question_master_id) ? await apiClient('admin/editQuestion', 'POST', reqData, null) : await apiClient('/admin/question', 'POST', reqData, null);
            if (res.responseCode === Response.STATUS_OK) {
                setSuccessConfirmation({ state: true, message: (defaultData.question_master_id) ?Constants.questionUpdateSuccessMsg : Constants.createQuestionSuccessMsg });
                handleRefresh();
                handleResetDefaultData();
            } else if (res.responseCode === Response.TOKEN_EXPIRED) {
                props.resetAccessToken();
            }
        }
        catch (err) {
            setConfirmationPopupData({ state: true, message: err.message, isAlert: true, type: 1 });
        }
    }

    const handleCancel = () => {
        setConfirmationPopupData({ state: true, message: Constants.confirmCancelMsg, isAlert: false, type: 1 })
    }

    const handleConfirmBack = () => {
        setConfirmationPopupData({ state: false, message: "", isAlert: false })
        props.toggle();
    }

    return (
        <Modal
            isOpen={props.isOpen}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <ModalHeader toggle={props.toggle} >Add Latex Answer</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmitForm}>
                    <div className="answer">
                        {optionsData.map((item, index) => (
                            <div key={index} className="answer__option">
                                <Label className="answer__option__label">{item.label}</Label>
                                <div className="answer__option__description">
                                    <div className="answer__option__description__type">
                                        {Constants.textTypeData.map((ele, i) => (
                                            <div key={i} className="answer__option__description__type__selection">
                                                <label className="answer__option__description__type__selection__label">{ele.label}</label>
                                                <input className="answer__option__description__type__selection__btn" type="radio" name={item.id} checked={item.optionType === ele.type ? true :false} value={ele.type} id={item.id} onChange={handleOptionTextType} />
                                            </div>))}
                                    </div>
                                    <Input className="answer__option__description__content" type="textarea" id={item.id} value={item.option} onChange={handleOptionDescriptionChange} />
                                </div>
                                {(item.optionType === 2 ) &&
                                    <div className="answer__option__preview">
                                        <label className="answer__option__preview__label">Preview</label>
                                        <div className="answer__option__preview__content">
                                            {item.option &&
                                                <MathJaxContext>
                                                    <MathJax 
                                                        renderMode={"pre"}
                                                        typesettingOptions={{ fn: "tex2chtml" }}
                                                        dynamic  
                                                        text= {item.option}
                                                    >
                                                    </MathJax>
                                                </MathJaxContext>
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        ))}
                        <div className="answer__field" >
                            <Label className="answer__field__label">Answer</Label>
                            {<Select
                                name="correct_answer"
                                value={answer}
                                options={formatOptionList(props.optionList)}
                                onChange={(e) => setAnswer(e)}
                                className="basic-multi-select answer__field__select"
                                classNamePrefix="select"
                                placeholder="Search..."
                                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 }) }}
                            />}
                        </div>
                        <div className="answer__field">
                            <Label className="answer__field__label" >Type</Label>
                            <Select
                                name="question_type"
                                value={type}
                                onChange={(e) => setType(e)}
                                options={formatTypeList(props.typeList)}
                                className="basic-multi-select answer__field__select"
                                classNamePrefix="select"
                                placeholder="Search..."
                                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 }) }}
                            />
                        </div>
                        <div className="answer__field">
                            <Label className="answer__field__label">Status</Label>
                            <Select
                                name="status"
                                value={status}
                                onChange={(e) => setStatus(e)}
                                options={formatStatusList(props.statusList)}
                                className="basic-multi-select answer__field__select"
                                classNamePrefix="select"
                                placeholder="Search..."
                                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 }) }}
                            />
                        </div>
                        <div className="answer__btn">
                            <Button className="answer__btn__submit" color="primary" type="submit" >Confirm</Button>
                            <Button className="answer__btn__cancel" color="danger" onClick={handleCancel} > Cancel </Button>
                        </div>
                    </div>
                </Form>

                {successConfirmation.state &&
                    <ConfirmationModal
                        isOpen={successConfirmation.state}
                        responseMessage={successConfirmation.message}
                        toggle={() => setSuccessConfirmation({ state: false, message: "" })}
                        type={"success"}
                    />
                }

                {confirmationPopupdata.state &&
                    <ConfirmPopup
                        message={confirmationPopupdata.message}
                        isOpen={confirmationPopupdata.state}
                        toggle={() => setConfirmationPopupData({ state: false, message: "", isAlert: false })}
                        isAlert={confirmationPopupdata.isAlert}
                        handleClick={confirmationPopupdata.type === 1 ? handleConfirmBack : handleSave}
                    />
                }

            </ModalBody>
        </Modal>
    );
}