import React, {useState} from 'react'
import { FaPen } from 'react-icons/fa'
import { Container , Row, Col, Card, CardBody, Button} from 'reactstrap'
import CardHeader from 'reactstrap/lib/CardHeader'
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import CountryEdit from './CountryEdit';

function CountryList(props) {
  const [countryList, setCountryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);
  const [countryEditData, setCountryEditData] = useState([])
  const [countryEditPopup, setCountryEditPopup] = useState(false);

  // api call to list bots
  const loadCountryList = async (reset) => {
    if ((reset && inProgress) || !loadMoreData) return;
    setInProgress(true);

    const res = await apiClient('/admin/botCountry', 'GET', null, {user_id:props.userId, access_token:props.accessToken});
    if (res.responseCode === Response.STATUS_OK) {     
      setCountryList(res.responseData.botCountryList);
      
      if (res.responseData.botCountryList.length === 0) setLoadMoreData(false);
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
    setInProgress(false);
    setLoading(false);
  }

   // opening the model and save the bot detail to state
  const handleClick = (data) => {
    setCountryEditPopup(true);
    setCountryEditData(data);
  };


  return (
    <Container>
    <Card>
      <CardHeader tag="h5" style={{color:"purple"}}>
        <span style={{fontSize:"30px"}}> Country Names</span> 
        <Button className="btn btn-light  text-center" style={{float:"right"}} 
          onClick={() => handleClick(countryList)} >
          <FaPen />
        </Button>
      </CardHeader>

      <CardBody>
        {loading && <h1>Loading...</h1>}
        {error && <h1 className="text-center">Error. Try Refreshing.</h1>}
        {!error && countryList.length === 0 && ((!inProgress && loadCountryList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}

        {countryList.length !== 0 && 
          <Row>
            {countryList.map(({bot_country_id, country_name}, index) => (
              <Col sm="3" key={index} style={{marginTop:'20px'}}>
              <Button onClick={() => handleClick(countryList)}
                style={{color:'black'}} 
                className="btn btn-outline-dark btn-lg  btn-light rounded-pill"
                block>
                {country_name}
              </Button> 
             </Col>
             
            ))}
          </Row>
        }
      </CardBody>
    </Card>

    {/* Modal to edit bot Names  */}
    <CountryEdit
      accessToken={props.accessToken}
      userId = {props.userId}
      isOpen={countryEditPopup}
      countryList={countryEditData}
      resetAccessToken={() => { props.resetAccessToken() }}
      toggle={() => { setCountryEditPopup(false); }}
      refresh = { () =>  { 
        setCountryList([]);
        setLoading(true);}
      }
    />
  </Container>
)
}

export default CountryList
