import React, {useState} from 'react'
import { FaPen } from 'react-icons/fa'
import { Container , Row, Col, Card, CardBody, Button} from 'reactstrap'
import CardHeader from 'reactstrap/lib/CardHeader'
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import BotProfileEdit from './BotProfileEdit';

//bot image style
const botImage = {
  'display': 'block',
  'padding': '20px',
  'width': 'auto',
  'height': 'auto',
  'maxWidth':'100px',
  'maxHeight':'100px'
}

function BotImages(props) {
  const [botData, setBotData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [loadMoreData, setLoadMoreData] = useState(true);
  const [botEditData, setBotEditData] = useState([])
  const [botProfileEditPopup, setBotProfileEditPopup] = useState(false);

  // api call to upload bot profile pic
  const loadBotProfileList = async (reset) => {
    if ((reset && inProgress) || !loadMoreData) return;
    setInProgress(true);

    const res = await apiClient('/admin/botProfilePic', 'GET', null, {user_id:props.userId, access_token:props.accessToken});
   
    if (res.responseCode === Response.STATUS_OK) {     
      setBotData(res.responseData.botProfilePicList);
      if (res.responseData.botProfilePicList.length === 0) setLoadMoreData(false);
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
    setInProgress(false);
    setLoading(false);
  }

  // opening the model and save the bot detail to state
  const handleProfileEdit = (data) => {
    setBotEditData(data);
    setBotProfileEditPopup(true);
  };


  return (
    <Container>
      <Card>
        <CardHeader tag="h5" style={{color:"purple"}}>
          <span style={{fontSize:"30px"}}> Bot Profile Pictures</span> 
          <Button className="btn btn-light  text-center" style={{float:"right"}} 
            onClick={() => handleProfileEdit(botData)} >
            <FaPen />
          </Button>
        </CardHeader>

        <CardBody>
          {loading && <h1>Loading...</h1>}
          {error && <h1 className="text-center">Error. Try Refreshing.</h1>}
          {!error && botData.length === 0 && ((!inProgress && loadBotProfileList(true) && false) || (!loading && (!loadMoreData ? <h1>No Data found</h1> : <h1>Loading...</h1>)))}

          {botData.length !== 0 && 
            <Row>
              {botData.map(({bot_profile_pic_id, bot_profile_pic}, index) => (
                <Col sm="3" className='text-center' key={index} style={{marginTop:'20px'}}>
                  <img style={botImage} onClick={() => handleProfileEdit(botData)} src={bot_profile_pic} style={{width:'auto', height:'auto',maxWidth:'100px', maxHeight:'100px'}} />
               </Col> 
              ))}
            </Row>
          }
        </CardBody>
      </Card>

      <BotProfileEdit
        accessToken={props.accessToken}
        userId = {props.userId}
        isOpen={botProfileEditPopup}
        botData={botEditData}
        resetAccessToken={() => { props.resetAccessToken() }}
        toggle={() => { setBotProfileEditPopup(false); }}
        refresh = { () =>  { 
          setBotData([]);
          setLoading(true);}}
        />
    </Container>
  )
}

export default BotImages

