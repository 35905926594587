import React, { useState, useEffect, useRef } from 'react'
import { Container, Button, CardBody, Card, Row, Col, CardHeader, Table, ModalBody, Modal, ModalHeader, Form, FormGroup, ButtonGroup } from 'reactstrap';
import { FaUpload, FaTrashAlt, FaCheck } from 'react-icons/fa';
import { MdModeEdit } from 'react-icons/md';
import '../../App.css';

import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import ConfirmationModal from '../QuizQuestions/ConfirmationModal';
import ConfirmPopup from '../QuizQuestions/ConfirmPopup';

function BannersList(props) {

  const [addNewBannerPopup, setAddNewBannerPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [successMsg, setSuccessMsg] = useState('');
  const [bannerList, setBannerList] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmPopupData, setConfirmPopupData] = useState({ state: false, message: "" });
  const bannerIdRef = useRef();
  
  React.useEffect(() => {
    displayBannerList();
  }, [])

  // api call to list banners
  const displayBannerList = async () => {
    setBannerList([])
    const res = await apiClient('/adminBannerList', 'GET', null, { user_id: props.userId, access_token: props.accessToken, type: props.type });
    if (res.responseCode === Response.STATUS_OK) {
      // sort the incoming banner_list in descending order as a new Upload always puts the image in the beginning of the list
      setBannerList(res.responseData.banner_list.sort((a, b) => {
        return +b.banner_id - +a.banner_id
      }));
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
  }

  //function to get upload URL for image
  const getUploadURL = async (file_extension) => {
    const res = await apiClient('/getUploadUrl', 'POST', { file_extension: file_extension });
    if (res.responseCode === Response.STATUS_OK) {
      return res.responseData;
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
  }

  // To add/upload new banner image
  const submitImage = async (file) => {
    try {
      // validate input type file
      if (typeof selectedImage === '' || selectedImage === null) return setErrorMessage("Please Select image");
      //BUGFIX: In case the filename has many period symbols then the previous logic wouldnt work!
      const file_extension = selectedImage.name.split('.').pop();
      const banner_name = await getUploadURL(file_extension);
      console.log(banner_name)
      // Headers to upload image to s3
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", selectedImage.type);
      //building up request data
      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: selectedImage,
        redirect: 'follow'
      };

      //upload the image using upload url (using promise to avoid delay)
      await fetch(banner_name.upload_url, requestOptions).then(response => response.text())

      // API call to save data
      const updatedData = { 
        user_id: props.userId,
        access_token: props.accessToken,
        banner_name: banner_name.image_id,
        type: props.type 
      }

      const submitResponse = await apiClient('/adminBanner', 'POST', updatedData);

      if (submitResponse.responseCode === Response.STATUS_OK) {
        setSuccessMsg('Banner Uploaded Successfully')
        setSuccessConfirmation(true);
        // ConfirmationModal Component will be removed from the DOM based on a setTimeout
        setTimeout(() => {
          displayBannerList();
          setSuccessConfirmation(false);
          setAddNewBannerPopup(false)
          setSelectedImage(null);
        }, 2000);
      } else if (submitResponse.responseCode === Response.TOKEN_EXPIRED) {
        props.resetAccessToken();
      } else {
        setError(submitResponse.responseMessage);
      }
    } catch (error) {
      setError("Something went wrong while Uploading Picture!");
    }
  }

  //function to update the selected banner
  const updateActive = async (banner_id) => {
    try {
      const res = await apiClient('/adminBannerUpdate', 'POST', { user_id: props.userId, access_token: props.accessToken, banner_id: banner_id });
      if (res.responseCode === Response.STATUS_OK) {
        setSuccessMsg('Selected Banner has been updated as Default!');
        setSuccessConfirmation(true);
        // ConfirmationModal Component will be removed from the DOM based on a setTimeout
        setTimeout(() => {
          displayBannerList();
          setSuccessConfirmation(false)
        }, 2000);

      } else if (res.responseCode === Response.TOKEN_EXPIRED) {
        props.resetAccessToken();
      } else {
        setSuccessConfirmation(res.responseMessage);
      }
    } catch (error) {
      setError("Something went wrong marking as default!");
    }
  }

  const handleConfirmDeleteBanner = (banner_id) => {
    bannerIdRef.current = banner_id;
    setConfirmPopupData({ state: true, message: "Are you sure you want to delete the Banner?" })
  }

  const handleDeleteBanner = async () => {
    setConfirmPopupData({state:false})
    try {
      const res = await apiClient('/admin/banner/delete', 'POST', { user_id: props.userId, access_token: props.accessToken, banner_id: bannerIdRef.current });
      if (res.responseCode === Response.STATUS_OK) {
        setSuccessMsg('Deleted Succefully');
        setSuccessConfirmation(true);
        // ConfirmationModal Component will be removed from the DOM based on a setTimeout
        setTimeout(() => {
          displayBannerList();
          setSuccessConfirmation(false)
        }, 2000);

      } else if (res.responseCode === Response.TOKEN_EXPIRED) {
        props.resetAccessToken();
      } else {
        setSuccessConfirmation(res.responseMessage);
      }
    }
    catch (err) {
      setError(err.message);
    }
  }

  return (
      <div className="align-items-center">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col sm="6">
                    <h5 className='text-center'>{props.title} Banner List</h5>
                  </Col>
                  <Col sm="6" className="d-flex justify-content-end">
                    <Button
                      className="btn btn-md add-new-btn d-flex justify-content-end"
                      onClick={() => setAddNewBannerPopup(true)}
                    >
                      Upload Banner <FaUpload />
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Table
                  size="md"
                  responsive
                  hover
                  className="table-striped table-bordered"
                >
                <thead>
                  <tr className="text-capitalize align-middle text-center">
                    <th>ID</th>
                    <th>File Name</th>
                    <th>Banner Image</th>
                    {/* <th>Active Image</th> */}
                    <th>Update Active Banner</th>
                  </tr>
                </thead>
                <tbody>
                  {error && (<h1 className="text-center">Error. Try Refreshing.</h1>)}
                  {!error && bannerList.length === 0 &&  <h1>No Data found</h1>}
                  {!error && bannerList.map((banner, index) => (
                    <tr key={index} className="text-center">
                      <td>{banner.banner_id}</td>
                      <td>{banner.banner_name}</td>
                      <td>
                        <img src={banner.banner_url} className="banner-img" />
                      </td>
                      {/* <td style={banner.is_active == 1 ? { color:'green' } : { color:'red' }}><FaCheck /> </td> */}
                      <td>
                        <ButtonGroup vertical>
                          <Button
                          className="mb-1"
                            onClick={() => updateActive(banner.banner_id)}
                            style={
                              banner.is_active == 1
                                ? { background: "green", border: "green" }
                                : { background: "gray", border: "gray" }
                            }>
                            {banner.is_active == 1? 'Active' : 'Mark Active'}<MdModeEdit />
                          </Button>
                          <Button className="add-new-btn mb-1" onClick={() => handleConfirmDeleteBanner(banner.banner_id)}>Delete</Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br />

        {addNewBannerPopup && (
          <Modal
            isOpen={addNewBannerPopup}
            size="lg"
            toggle={() => setAddNewBannerPopup(false)}
          >
            <ModalBody className="banner-upload">
              <Row>
                <Col sm="12">
                  <h5 className="modal-heading">Upload Banner</h5>
                  {selectedImage && (
                    <div>
                      <img
                        src={URL.createObjectURL(selectedImage)}
                        className="full-width"
                      />
                      <button
                        onClick={() => setSelectedImage(null)}
                        className="rm-img"
                      >
                        Remove <FaTrashAlt />
                      </button>
                    </div>
                  )}
                </Col>
                <Col sm="12">
                  <Form>
                    <FormGroup row>
                      <Col sm={12} className="d-flex justify-content-center">
                        <input
                          type="file"
                          name="banner_name"
                          id="banner_name"
                          accept="image/*"
                          onChange={(e) => {
                            setSelectedImage(e.target.files[0]);
                          }}
                          required
                        />
                        <label className="red-error">{errorMessage}</label>
                      </Col>
                      <Col sm={6} className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-success"
                          onClick={() => submitImage(selectedImage)}
                        >
                          Upload Banner
                        </button>
                      </Col>
                      <Col sm={6} className="d-flex justify-content-start">
                        <Button
                          color="danger"
                          onClick={() => setAddNewBannerPopup(false)}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        )}
        {successConfirmation && (
          <ConfirmationModal
            isOpen={successConfirmation}
            responseMessage={successMsg}
            toggle={() => {
              setSuccessConfirmation(false);
            }}
            type={"success"}
          />
        )}

        {confirmPopupData.state &&
          <ConfirmPopup
            message={confirmPopupData.message}
            isOpen={confirmPopupData.state}
            toggle={() => setConfirmPopupData({ state: false, message: "" })}
            handleClick={handleDeleteBanner}
          />
        }
      </div>
  );
}

export default BannersList;

