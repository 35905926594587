import React from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

function ConfirmPopup(props) {
  return (
    <Modal isOpen={props.isOpen}
    size="md"
    centered>
      <ModalBody>
        {/* <h1 className='text-center mt-4 mb-6'>Leaderboard Rewards</h1> */}
       
        <div className='text-center'>
          <p className='m-5'>{props.message}</p>
          <Button  color="danger" onClick={()=>props.toggle()}>{props.isAlert ? "Ok" : "No" }</Button>   &emsp;&emsp;
          {!props.isAlert && <Button  color="success" onClick={()=>{props.handleClick();}} >Yes</Button>}

        </div>
      </ModalBody>
    </Modal>
  )
}

export default ConfirmPopup
