import React from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { Button, Table } from "reactstrap";
import '../../App.css';
import Constants from "../../lib/constant";

function NormalQuestionTable({headers, data, ...props}) {
  const [deleteState, setDeleteState] = React.useState({});

  let type = Constants.QUESTION_TYPE_NORMAL;
  let { gradeId, subjectId } = useParams();

  //function to check and uncheck question using variable "deleteState"
  const handleDelete =async (event) => {
    let ids = props.questionDeleteIds;
    if (event.target.checked === true) {
      setDeleteState({ ...deleteState, [parseInt(event.target.id)]: event.target.checked });
      ids.push(event.target.id)
      props.setQuestionDeleteIds(ids)
    } else {
      setDeleteState({ ...deleteState, [parseInt(event.target.id)]: event.target.checked });
      ids = ids.filter(id => id !== event.target.id)
      props.setQuestionDeleteIds(ids)
    }
  };

  return (
    <Table size="md" responsive hover>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th style={{ 'padding':'10px'}} key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {data.map(({question_master_id, question_id,question_text,attempts_ratio,question_status,answer_id, options, question_text_type,time}, i) => (
          <tr key={i}>
            {props.isDelete && 
              <td className="align-middle" >
                <input className='largerCheckbox' disabled={question_status === Constants.QUESTION_STATUS_LIVE? true:''} id={question_master_id}  type="checkbox" onClick={handleDelete} checked={deleteState[question_master_id]}/>
                &emsp;{question_master_id}
              </td>
            }
            {!props.isDelete && 
              <td className="align-middle">
                <Button className="btn btn-light btn-circle btn-xl" style={{color: "black", fontSize:'x-large'}} 
                  onClick={() => props.handleClick({question_master_id,question_id, question_text,question_status, answer_id, options, type, gradeId, subjectId, question_text_type,time})}
                ><FaRegEdit /></Button>
                &nbsp;{question_master_id}
              </td>
            }
            <td className="align-middle text-center">{question_text}</td>
            <td className="align-middle text-center" style={attempts_ratio >= 50 ? { color:'green' } : { color:'red' }}>
              {attempts_ratio !== null ? `${attempts_ratio}%`: ''}
            </td>
            <td className="align-middle text-center"  style={question_status===Constants.QUESTION_STATUS_LIVE ? { color:'green' } : { color:'red' }}>
              {question_status===Constants.QUESTION_STATUS_LIVE?'LIVE':'ON HOLD'}
            </td>
          </tr>
        ))}
      </tbody>
    </Table >
  );
}

export default NormalQuestionTable
