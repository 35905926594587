import React, {useState} from 'react'
import { Modal, ModalBody, ModalHeader, Form,Col, FormGroup, Button, Label } from 'reactstrap';
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import '../../App.css';
import { FiUpload } from 'react-icons/fi';
import ImageUpload from './ImageUpload';
import ConfirmPopup from '../QuizQuestions/ConfirmPopup';
import ConfirmationModal from '../QuizQuestions/ConfirmationModal';

function BotProfileEdit({botData, ...props}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [uploadModal, setUploadModal] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState([])
  const [botProfileUrls, setBotProfileUrls] = useState();

  const [backConfirmation, setBackConfirmation] = useState(false);
  const [saveConfirmation, setSaveConfirmation] = useState(false);
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [message, setMessage] = useState('')

  //confirm box to go back
  const handleCancel = () => {
    setBackConfirmation(false)
    setMessage('')
    props.toggle();
  }
  
  //function to reset the data field once after model is closed
  const resetData = () => {
    if (botProfileUrls !== '') setBotProfileUrls('');
    if (errorMessage !== '') setErrorMessage('');
  }  

    
  //on submitting the form, updating bot details
  const submitBotEditForm = (e) => {
    e.preventDefault();
    setSaveConfirmation(true);
    setMessage('Are you sure you want to save the changes?');
  }

  const saveProfile = async () => {
    setSaveConfirmation(false); setMessage('')
    setErrorMessage('');
    
    //making api call to update data
    let updatedData = {user_id:props.userId, access_token:props.accessToken}
        
    let BotList = [];
    Object.keys(botProfileUrls).forEach(key => BotList.push({
      bot_profile_pic_id: key,
      bot_profile_pic: botProfileUrls[key]
    }));

    updatedData.bot_data = JSON.stringify(BotList);

    const res = await apiClient('/admin/botProfilePic', 'POST', updatedData, null);
    if (res.responseCode === Response.STATUS_OK) {
      setSuccessMsg('CHANGES SAVED')
      setSuccessConfirmation(true);
      handleRefresh();
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setErrorMessage(res.responseMessage)
    }
  }

  const handleRefresh = () => {
    window.setTimeout(() => {
      setSuccessConfirmation(false);
      setSuccessMsg('');
      props.refresh()
      props.toggle(); 
    }, 2000); 
  }

  return (
    <Modal
      isOpen={props.isOpen}
      size="lg"
      centered
    >
      <ModalHeader toggle={props.toggle}>
         BOT PROFILE LIST
      </ModalHeader>
      <p className="text-center">THE IMAGE HAS TO BE 500X500 PIXELS</p>
      <ModalBody style={{'overflowY':'scroll', 'position': 'relative', 'height':'50vh'}}>
      {!props.isOpen && resetData()}
        <Form onSubmit={submitBotEditForm} >
          {botData.map(({bot_profile_pic_id, bot_profile_pic}, index) => (
            <FormGroup row key={index}>
               <Col md={2} />
              <Label md={3} style={{ margin: 'auto 0'}} for="user_name">Profile Picture {index+1}</Label>
              <Col  md={3}>
                <img style={{width:'100px', height:'100px'}}
                  src={bot_profile_pic}
                />
              </Col>

              <Button md={1} className='btn text-center' style={{background:'white', color:'black', borderColor:'white'}}
                onClick={()=> {setUploadModal(true); setSelectedProfile([bot_profile_pic_id, bot_profile_pic])}}
              >
                <FiUpload />
              </Button>
              <Col md={2} /><br/>
            </FormGroup>
          ))}
          
          <FormGroup style={{"textAlign": "center", 'marginTop':'30px'}}>
            <Button color="danger" onClick={()=> {setMessage('Are you sure want to go back?');setBackConfirmation(true)}} > Cancel </Button> &emsp;
            <Button  color="primary"  type="submit" >Save</Button>
          </FormGroup>
          {errorMessage && <p className="mt-2 text-danger text-center">{errorMessage}</p>}
        </Form>
      </ModalBody>

      {uploadModal && 
        <ImageUpload 
        accessToken={props.accessToken}
        userId = {props.userId}
        isOpen={uploadModal}
        profileData = {selectedProfile}
        resetAccessToken={() => { props.resetAccessToken() }}
        toggle = {() => setUploadModal(false)}
        botImageUrl = {(data) => {setBotProfileUrls(data)}}
        profileUrl = {botProfileUrls}
        />
      }

      {backConfirmation && 
      <ConfirmPopup
        message={message}
        isOpen={backConfirmation}
        toggle={()=>setBackConfirmation(false)}
        handleClick={handleCancel}
      />
      }

    {saveConfirmation && 
      <ConfirmPopup
        message={message}
        isOpen={saveConfirmation}
        toggle={()=>setSaveConfirmation(false)}
        handleClick={saveProfile}
      />
      }


      {successConfirmation && 
      <ConfirmationModal
        isOpen={successConfirmation}
        responseMessage={successMsg}
        toggle={() => { setSuccessConfirmation(false); }}
        type={"success"}
      />
      }
     


    </Modal>
  )
}

export default BotProfileEdit
