import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import ConfirmationModal from './ConfirmationModal';
import ConfirmPopup from './ConfirmPopup';

export default function UpdateModal(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [questionId, setQuestionId] = useState('');
  const [questionMasterId, setQuestionMasterId] = useState('');
  const [question, setQuestion] = useState('');

  const [optionOne, setOptionOne] = useState('');
  const [optionTwo, setOptionTwo] = useState('');
  const [optionThree, setOptionThree] = useState('');
  const [optionFour, setOptionFour] = useState('');
  const [time, setTime] = useState(0);

  const [defaultAnswer, setDefaultAnswer] = useState([]);
  const [defaultType, setDefaultType] = useState([]);
  const [defaultStatus, setDefaultStatus] = useState([]);
  const [answerDropdown, setAnswerDropdown] = useState([]);
  const [typeDropdown, setTypeDropdown] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([]);

  const [backConfirmation, setBackConfirmation] = useState(false);
  const [editConfirmation, setEditConfirmation] = useState(false);
  const [successConfirmation, setSuccessConfirmation] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    if(props.questionData.options) 
    {
      setOptionOne(props.questionData.options[0].option_text)
      setOptionTwo(props.questionData.options[1].option_text)
      setOptionThree(props.questionData.options[2].option_text)
      setOptionFour(props.questionData.options[3].option_text)
      
    }
    setTime(props.questionData.time)
    setQuestion(props.questionData.question_text)
    setQuestionId(props.questionData.question_id)
  }, [props])

  //function to load dropdowns(video, quiz)
  const loadDropdowns = () => {
    let selectableOptions = [], defaultOptionValues = [];
    let selectableType = [], defaultTypeValues = [];
    let selectableStatus = [], defaultStatusValues = [];
      
    //initializing the answer  dropdown
    props.questionData.options.map((option,i) => {
      selectableOptions.push({value: (i+1), label: `Option ${i+1}`});
      if (props.questionData.answer_id === (option.option_id)) {
        defaultOptionValues.push({value: (i+1), label: `Option ${i+1}`})};
      return option;
    });
  
    if (answerDropdown.length === 0) setAnswerDropdown(selectableOptions);
    if (defaultOptionValues.length !== 0 && defaultAnswer.length === 0) setDefaultAnswer(defaultOptionValues[0]);
  
    //initializing the type dropdown
    props.typeList.map(type => {
      selectableType.push({value: type.type_id, label: type.type_title});
      if (props.questionData.type === type.type_id) {
        defaultTypeValues.push({value: type.type_id, label: type.type_title})};
        return type;
    });

    if (typeDropdown.length === 0) setTypeDropdown(selectableType);
    if (defaultTypeValues.length !== 0 && defaultType.length === 0 ) setDefaultType(defaultTypeValues[0]);

    //initializing the status dropdown
    props.statusList.map(status => {
      selectableStatus.push({value: status.status_id, label: status.status_title});
      if (props.questionData.question_status === status.status_id) {
        defaultStatusValues.push({value: status.status_id, label: status.status_title})};
        return status;
    });

    if (statusDropdown.length === 0) setStatusDropdown(selectableStatus);
    if (defaultStatusValues.length !== 0 && defaultStatus.length === 0 ) setDefaultStatus(defaultStatusValues[0]);
  }

  //function to reset the data field once after model is closed
  const resetData = () => {
    if (questionId !== '') setQuestionId('');
    if (question !== '') setQuestion('');
    if (optionOne !== '') setOptionOne('');
    if (optionTwo !== '') setOptionTwo('');
    if (optionThree !== '') setOptionThree('');
    if (optionFour !== '') setOptionFour('');
    if(defaultAnswer.length !== 0) setDefaultAnswer([]);
    if(defaultType.length !== 0) setDefaultType([]);
    if(defaultStatus.length !== 0) setDefaultStatus([]);
    if(answerDropdown.length !==0) setAnswerDropdown([]);
    if(typeDropdown.length !==0) setTypeDropdown([]);
    if(statusDropdown.length !==0) setStatusDropdown([]);
    if (errorMessage !== '') setErrorMessage('');
    
  }  

  const submitEditForm =  (e) => {
    e.preventDefault();
    setEditConfirmation(true);
    setMessage('Are you sure you want to save the changes?');
  }
  

  const handleUpdateChanges = async () => {
    setEditConfirmation(false); setMessage('')
    let updateData = {user_id:props.userId, access_token:props.accessToken}
    if (typeof questionId !== "undefined" && questionId.trim() !== "") updateData.question_id = questionId;
    if (typeof question !== "undefined" && question.trim() !== "") updateData.question_text = question;
    if (typeof optionOne !== "undefined" && optionOne.trim() !== "") updateData.option1 = optionOne;
    if (typeof optionTwo !== "undefined" && optionTwo.trim() !== "") updateData.option2 = optionTwo;
    if (typeof optionThree !== "undefined" && optionThree.trim() !== "") updateData.option3 = optionThree;
    if (typeof optionFour !== "undefined" && optionFour.trim() !== "") updateData.option4 = optionFour;
    if (typeof defaultAnswer.value !== "undefined" ) updateData.answer = defaultAnswer.value;
    if (typeof defaultType.value !== "undefined" ) updateData.type = defaultType.value;
    if (typeof defaultStatus.value !== "undefined" ) updateData.question_status = defaultStatus.value;
   
    updateData.question_master_id = props.questionData.question_master_id;
    updateData.grade = props.questionData.gradeId;
    updateData.subject_id = props.questionData.subjectId;
    updateData.time=time;
    const res = await apiClient('admin/editQuestion', 'POST', updateData, null);
    
    if (res.responseCode === Response.STATUS_OK) {
      setSuccessMsg('QUESTION UPDATED')
      setSuccessConfirmation(true);
      handleRefresh()
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setErrorMessage(res.responseMessage)
    }
  }

  const handleRefresh = () => {
    window.setTimeout(() => {
      setSuccessConfirmation(false);
      setSuccessMsg('');
      props.refresh(props.questionData.type)
      props.toggle(); 
    }, 2000); 
  }

  const handleCancel = () => {
    setBackConfirmation(false)
    setMessage('')
    props.toggle();
  }




  return (
    <Modal
      isOpen={props.isOpen}
      size="lg"
      centered
    >
      <ModalHeader toggle={props.toggle}>
          Edit Question
      </ModalHeader>
      <ModalBody>
        {props.isOpen && loadDropdowns()}
        {!props.isOpen && resetData()}
       
          <Form onSubmit={submitEditForm}>
             <FormGroup row>
              <Label for="question_id" sm={4}>ID No.</Label>
              <Col sm={5}>
                <Input 
                  type="text"value={questionMasterId ? questionMasterId : props.questionData.question_master_id} name="question_id" 
                  disabled
                  />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="question_title" sm={4}>Question Text</Label>
              <Col sm={5}>
                <Input 
                  type="textarea"  name="question_title" 
                  onChange={(e) => { setQuestion(e.target.value);}}
                  value={question ? question : props.questionData.question_text}
                  />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_one" sm={4}>Option One</Label>
              <Col sm={5}>
                <Input 
                  value={optionOne ? optionOne : props.questionData.option_one}
                  onChange={(e) => { setOptionOne(e.target.value);}} type="text" name="option_one" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_two" sm={4}>Option Two</Label>
              <Col sm={5}>
                <Input 
                   value={optionTwo ? optionTwo : props.questionData.option_two}
                  onChange={(e) => { setOptionTwo(e.target.value);}} type="text" name="option_two" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_three" sm={4}>Option Three</Label>
              <Col sm={5}>
                <Input 
                  value={optionThree ? optionThree : props.questionData.option_three}
                  onChange={(e) => { setOptionThree(e.target.value);}} type="text" name="option_three" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
              <Label for="option_four" sm={4}>Option Four</Label>
              <Col sm={5}>
                <Input value={optionFour ? optionFour : props.questionData.option_four}
                  onChange={(e) => { setOptionFour(e.target.value);}} type="text" name="option_four" />
              </Col>
            </FormGroup>
            <br/>

            <FormGroup row>
            <Label for="correct_answer" sm={4}>Answer</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultAnswer}
                name="correct_answer"
                options={answerDropdown}
                onChange={(e) => setDefaultAnswer(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>
          <br/>

          <FormGroup row>
            <Label for="question_type" sm={4}>Type</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultType}
                name="question_type"
                options={typeDropdown}
                onChange={(e) => setDefaultType(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>

          <br/>
          <FormGroup row>
            <Label for="status" sm={4}>Status</Label>
            <Col sm={5}>
            {<Select  
                defaultValue={defaultStatus}
                name="status"
                options={statusDropdown}
                onChange={(e) => setDefaultStatus(e)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search..."
                styles={{ control: provided => ({ ...provided, "minWidth": 228, margin: 0 })}}
              />}
            </Col>
          </FormGroup>
          <br/>

          <FormGroup row>
              <Label for="time" sm={4}>Time(Seconds)</Label>
              <Col sm={5}>
                <Input value={time}
                  onChange={(e) => { setTime(e.target.value);}} type="number" name="time"  />
              </Col>
            </FormGroup>
            <br/>
          <br/>
            <FormGroup style={{"textAlign": "center"}}>
            <Button color="danger" onClick={()=> {setMessage('Are you sure want to go back?');setBackConfirmation(true)}} > Cancel </Button> &emsp;
            <Button  color="primary"  type="submit" >Save</Button>

            </FormGroup>
            {errorMessage && <p className="mt-2 text-danger text-center">{errorMessage}</p>}

          </Form>
       
      </ModalBody>

      {backConfirmation && 
        <ConfirmPopup
          message={message}
          isOpen={backConfirmation}
          toggle={()=>setBackConfirmation(false)}
          handleClick={handleCancel}
        />
      }

      {editConfirmation && 
      <ConfirmPopup
        message={message}
        isOpen={editConfirmation}
        toggle={()=>setEditConfirmation(false)}
        handleClick={handleUpdateChanges}
      />
      }

      {successConfirmation && 
      <ConfirmationModal
        isOpen={successConfirmation}
        responseMessage={successMsg}
        toggle={() => { setSuccessConfirmation(false); }}
        type={"success"}
      />
      }
    </Modal>
  );
}