
import React, { useEffect, useState } from 'react';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Card, CardTitle, CardText, Container } from 'reactstrap';
import classNames from 'classnames';
import BannersList from './BannersList';

const containerStyle = {
    height: '90vh',
    overflowY: 'auto'
}
  

function BannerLanding (props) {

    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        props.setRouteBreadCrumbs([{route: '/banners_list', title: 'Banners List'}]);
    }, [])
    
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }
    return <>
        <Nav tabs className="banners">
            <NavItem>
                <NavLink
                className={classNames({ active: activeTab === "1" })}
                onClick={() => toggle("1")}
                >
                Desktop
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink
                className={classNames({ active: activeTab === "2" })}
                onClick={() => toggle("2")}
                >
                Mobile
                </NavLink>
            </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Container style={containerStyle} className="banners-list">
                        <BannersList userId={props.userId} resetAccessToken={props.resetAccessToken} accessToken={props.accessToken} title="Desktop" type="1"></BannersList>
                    </Container>
                </TabPane>
                <TabPane tabId="2">
                    <Container style={containerStyle} className="banners-list">
                        <BannersList userId={props.userId} resetAccessToken={props.resetAccessToken} accessToken={props.accessToken} title="Mobile" type="2"></BannersList>
                    </Container>
                </TabPane>
            </TabContent>
        </>
}

export default BannerLanding;
