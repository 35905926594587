import React from 'react'
import { Container } from 'reactstrap';
import BotNames from './BotNames';
import BotImages from './BotImages';
import CountryList from './CountryList';

const containerStyle = {
  height: '90vh',
  overflowY: 'auto'
}

function BotList(props) {
  React.useEffect(() => {
    props.setRouteBreadCrumbs([{route: '/bot_list', title: 'Bot List'}]);
  }, [])

  return (
    <Container style={containerStyle}  >
      <div className="d-flex flex-column align-items-center">
        <h1>Bots</h1><br/>
        <BotNames 
          routeBreadCrumbs={props.routeBreadCrumbs}
          setRouteBreadCrumbs={props.setRouteBreadCrumbs}
          resetAccessToken={props.resetAccessToken}
          accessToken={props.accessToken}
          userId = {props.userId}
        />
        <br/>
        <BotImages 
          routeBreadCrumbs={props.routeBreadCrumbs}
          setRouteBreadCrumbs={props.setRouteBreadCrumbs}
          resetAccessToken={props.resetAccessToken}
          accessToken={props.accessToken}
          userId = {props.userId}
        />
        <br/>
        <CountryList
          routeBreadCrumbs={props.routeBreadCrumbs}
          setRouteBreadCrumbs={props.setRouteBreadCrumbs}
          resetAccessToken={props.resetAccessToken}
          accessToken={props.accessToken}
          userId = {props.userId}
        />

      </div>     
    </Container>
  )
}

export default BotList
