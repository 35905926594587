import React, {useState,useEffect} from 'react'
import { ModalBody,Modal,ModalHeader , Button} from 'reactstrap';
import apiClient from '../../lib/apiClient';
import Response from '../../lib/Response';
import ImageUploader from 'react-images-uploading';

const fileContainer = {
  background: '#fff',
  boxShadow: '2px 2px 3px 0 rgb(0 0 0 / 5%)',
  position: 'relative',
  borderRadius: '10px',
  padding: '20px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  margin: '10px auto',
  transition: 'all .3s ease-in'
};

const buttonUpload = {
  padding: '6px 23px',
  borderRadius: '30px',
  fontWeight: '300',
  fontSize: '14px',
  margin: '10px 0',
  transition: 'all .2s ease-in',
  cursor: 'pointer',
  outline: 'none',
  border: 'none'
}

const errorContainer = {
    maxWidth: '300px',
    fontSize: '12px',
    color: 'red',
    textAlign: 'left'
}

function ImageUpload(props) {
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('')
  const [images, setImages] = useState([]);
  const [disabled, setDisabled] = useState(true)

  //function to get upload URL for image
  const getUploadURL = async (extention) => {
    const res = await apiClient('/get/uploadUrl', 'POST', { file_extension: extention});

    if (res.responseCode === Response.STATUS_OK) {
      return { upload_url: res.responseData.upload_url, key: res.responseData.image_id };
    } else if (res.responseCode === Response.TOKEN_EXPIRED) {
      props.resetAccessToken();
    } else {
      setError(true);
    }
  }
 
  const onChange = async (pictureFiles, addUpdateIndex) => {
    // data for submit
    setMessage('Please wait...image is uploading'); setDisabled(false)
    let updatedData = {};
    setImages(pictureFiles);
    let extension = pictureFiles[0].file.name.split('.')[pictureFiles[0].file.name.split('.').length - 1];
    
    //making api call inside promise to avoid loading delay
    const [uploadUrl] = await Promise.all([
      (typeof extension !== "undefined" && extension !== '' ) ? getUploadURL(extension) : undefined,
    ]);

    var requestOptions = {};
    if (typeof uploadUrl !== "undefined") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", pictureFiles[0].file.type);

      //building up request data
      requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: pictureFiles[0].file,
        redirect: 'follow'
      };
    }

    //making api call to fetch image url
    await Promise.all([
      typeof uploadUrl !== "undefined" ? fetch(uploadUrl.upload_url, requestOptions).then(response => response.text()) : '',
    ]);

    if (typeof uploadUrl !== "undefined") updatedData.bot_profile_pic = uploadUrl.key;

    //save uploaded image url to props object 
    props.botImageUrl(
      {
        ...props.profileUrl,
        [props.profileData[0]]: updatedData.bot_profile_pic
      }
    )

    setMessage('Image Uploaded Successfully')

    window.setTimeout(() => {
      setMessage('');
      setDisabled(true)
      setImages([]);
      props.toggle()
    }, 2000);
   
  };
  
  return (
    <Modal  
    isOpen={props.isOpen}   
    size="lg"
    centered>
      <ModalHeader toggle={props.toggle}></ModalHeader>
       <ModalBody>
        {error && <h1 className="text-center">Error while uploading...</h1>}
       
         <ImageUploader
            multiple={false}
            value={images}
            maxNumber={1}
            acceptType={['jpg', 'gif', 'png']}
            onChange={onChange}
            dataURLKey="data_url"
            resolutionType={'less'}
            resolutionWidth={500}
            resolutionHeight={500}

      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (

          
          // write your building UI
          <div className="upload__image-wrapper" style={fileContainer}>
             <p className="text-center">THE IMAGE HAS TO BE 500X500 PIXELS</p>
            <Button
            disabled={!disabled}
              style={buttonUpload}
              onClick={onImageUpload}
              {...dragProps}
            >
              Select Image
            </Button>
            <br/>
            &nbsp;

            {errors && 
              <div className="errorsContainer" style={errorContainer}>
                {errors.resolution && <span>Selected file is not match your desired resolution</span>}
              </div>
            }

            <br/>

            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image['data_url']} alt="" width="100" />
                {/* <div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)}>Change</button>
                  <button onClick={() => onImageRemove(index)}>Remove</button>
                </div> */}
              </div>
            ))}

            <br/>

            {message && <p className="mt-2 text-secondary text-center">{message}</p>}

          </div>


        )}
      </ImageUploader>
       </ModalBody>
    
    </Modal>
    
  )
}

export default ImageUpload
